import axios from "axios";

const baseApiConfig = {
  baseURL: import.meta.env.VITE_SENDOLA_BLACK_IAM_BASE_URL,
  apiKey: import.meta.env.VITE_SENDOLA_BLACK_IAM_API_KEY,
};

const baseApi = axios.create({
  baseURL: baseApiConfig.baseURL,
});

baseApi.interceptors.request.use((config) => {
  config.headers["x-api-key"] = baseApiConfig.apiKey;
  config.headers["Content-Type"] = "application/x-www-form-urlencoded";

  return config;
});

const paymentsBffConfig = {
  baseURL: import.meta.env.VITE_GLOBAL_PAYMENTS_BFF_BASE_URL,
  apiKey: import.meta.env.VITE_GLOBAL_PAYMENTS_BFF_API_KEY,
};

const paymentsBffApi = axios.create({
  baseURL: paymentsBffConfig.baseURL,
});

paymentsBffApi.interceptors.request.use((config) => {
  config.headers["x-api-key"] = paymentsBffConfig.apiKey;

  return config;
});

const mondayHookApi = axios.create({
  baseURL: import.meta.env.VITE_GLOBAL_PABBLY_BASE_URL,
});

export { baseApi, paymentsBffApi, mondayHookApi };
