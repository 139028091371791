import { ReactNode } from "react";
import classNames from "classnames";

interface AuthCardProps {
  show?: boolean;
  className?: string;
  children?: ReactNode;
  allowClickOutside?: boolean;
  close?: () => void;
}

export const AuthCard = ({
  show = false,
  className,
  children,
  allowClickOutside = true,
  close,
}: AuthCardProps) => {
  return (
    <div
      className={classNames(
        "w-full h-full flex justify-center items-center max-lg:items-start fixed top-0 z-40 transition-all duration-500 ease-in-out overflow-y-auto pt-20 pb-10",
        {
          "-right-1": show,
          "-right-[150%]": !show,
        }
      )}
      onClick={() => close && allowClickOutside && close()}
    >
      <div
        onClick={(event) => {
          event.stopPropagation();
        }}
        className={classNames(
          `
          lg:absolute 
          lg:rounded-e-none 
          lg:right-0 
          gold-radial-gradient 
          border-[1px]  
          lg:top-32 
          p-4 
          px-8 
          border-sendolab-gold2 
          w-1/4 
          h-[500px]
          min-w-80 
          md:min-w-96
          max-md:w-full
          max-md:max-w-[360px] 
          max-w-[420px] 
          overflow-y-auto
          rounded-2xl 
          z-50
          shadow-sendolab-gold2
          shadow-[0_0_15px_0]`,
          className
        )}
      >
        {children}
      </div>
    </div>
  );
};
