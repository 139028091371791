import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { PasswordsForm } from "@/components/PasswordsForm";
import { Passwords } from "@/models/Auth";
import { useResetPasswordStore } from "@/stores/auth/resetPassword.store";

interface ChangePasswordFormProps {
  onLoginClick: () => void;
  onSuccess: () => void;
}

const ChangePasswordForm = ({
  onLoginClick,
  onSuccess,
}: ChangePasswordFormProps) => {
  const [t] = useTranslation("global");
  const { error, changePassword } = useResetPasswordStore();

  const [searchParams] = useSearchParams();

  const resetPasswordToken = useMemo(() => {
    return searchParams.get("reset_token");
  }, [searchParams]);

  const onSubmit = async (data: Passwords) => {
    if (!resetPasswordToken) {
      close();
      return;
    }

    const succeeded = await changePassword({
      password: data?.password,
      confirmPassword: data?.confirmPassword,
      resetPasswordToken,
    });

    if (succeeded) {
      onSuccess();
    }
  };

  return (
    <div>
      <h3
        className={`
        text-2xl 
        font-semibold 
        p-4 
        text-center 
        bg-gradient-to-r 
        from-sendolab-gold
        via-sendolab-gold3
        to-sendolab-gold4 
        text-transparent 
        bg-clip-text`}
      >
        {t("Auth.ResetPassword")}
      </h3>
      <p className="text-[#E2E1D9] text-lg font-semibold mb-4">
        {t("Auth.CreateNewPassword")}
      </p>

      <PasswordsForm error={error} onSubmit={onSubmit} />

      <div
        className="text-center text-xs font-normal mt-2 mb-4 cursor-pointer"
        onClick={onLoginClick}
      >
        <span className="text-white mr-2">
          {t("Auth.AlreadyHaveAnAccount")}
        </span>
        <span className="text-sendolab-light-turquoise font-semibold underline">
          {t("Auth.LogIn")}
        </span>
      </div>
    </div>
  );
};

export default ChangePasswordForm;
