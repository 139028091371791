import { Button } from "@/components/Button";
import { Input } from "@/components/Input";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ResetPasswordPayload } from "@/models/Auth";
import { useEffect, useState } from "react";
import { useResetPasswordStore } from "@/stores/auth/resetPassword.store";
import CountdownTimer from "@/components/CountdownTimer";
import {
  countDownRemaining,
  countDownReminingReset,
} from "@/utilities/LocalStorage";

interface ResetPasswordFormProps {
  onLoginClick: () => void;
}

export const ResetPasswordForm = ({ onLoginClick }: ResetPasswordFormProps) => {
  const [t] = useTranslation("global");
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const [resendEnabled, setResendEnabled] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  const { error, sendResetPasswordToken } = useResetPasswordStore();

  const hasCountDownRemaining = countDownRemaining();

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<ResetPasswordPayload>({
    defaultValues: {
      email: "",
    },
  });

  const onSubmit = async (data: ResetPasswordPayload) => {
    setLoading(true);
    const succesfullySent = await sendResetPasswordToken({
      email: data?.email,
    });

    if (succesfullySent) {
      setEmailSent(true);
      setResendEnabled(false);
      countDownRemaining(3);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (hasCountDownRemaining) {
      setResendEnabled(false);
      setEmailSent(true);
    }
  }, []);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col w-full h-full gap-3 p-3"
    >
      <p className="text-sendolab-gold font-semibold text-2xl text-center select-none">
        {t("Auth.ResetPassword")}
      </p>
      <p className="text-sendolab-white text-sm text-center select-none mb-4">
        {t("Auth.ResetPasswordInstructions")}
      </p>
      <Input
        type="text"
        placeholder={t("Auth.EmailPlaceholder")}
        error={Boolean(errors?.email)}
        errorMessage={errors?.email?.message}
        {...register("email", {
          required: true,
        })}
      />
      {error && !emailSent && (
        <p className="w-full text-sm text-center text-red-500 h-20">
          {t("Auth.ResetPasswordSuccess")}
        </p>
      )}
      {emailSent && (
        <p className="w-full text-sm text-center text-green-300 h-12">
          {t("Auth.ResetPasswordSuccess")}
        </p>
      )}

      {!resendEnabled && (
        <div className="w-full flex flex-col gap-2 mb-4 items-center">
          <CountdownTimer
            text="You can resend the link in: "
            initialTime={hasCountDownRemaining || 180}
            onComplete={() => setResendEnabled(true)}
          />
        </div>
      )}

      <div className="flex flex-1" />

      <Button
        block
        type="submit"
        size="md"
        text={t("Auth.ResetPasswordButton")}
        loading={loading}
        disabled={!resendEnabled && emailSent}
        className="bg-sendolab-gold2 !border-0 !font-normal text-base py-3 disabled:bg-stone-600"
      />
      <div
        className="text-center text-xs font-normal rounded-full hover:bg-white/10 py-2 px-4 cursor-pointer"
        onClick={() => {
          countDownReminingReset();
          onLoginClick();
        }}
      >
        <span className="text-sendolab-light-turquoise font-semibold underline">
          {t("Auth.BackToLogin")}
        </span>
      </div>
    </form>
  );
};
