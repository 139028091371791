
export const countDownRemaining = (minutes: number = 0) => {
  const storedRemaining = localStorage.getItem('hasRemaining');
  const currentDate = new Date();
  const numberOfMlSeconds = currentDate.getTime();

  if (minutes === 0 && !storedRemaining) return;

  if (storedRemaining) {
    const addMlSeconds = Number(storedRemaining);

    if(numberOfMlSeconds < addMlSeconds) {
      return Number(((addMlSeconds - numberOfMlSeconds) / 1000).toFixed(0));
    } else {
      countDownReminingReset();
    }

  } else {
    const addMlSeconds = minutes * 60000;
    localStorage.setItem('hasRemaining', `${numberOfMlSeconds + addMlSeconds}`);
  }

  return undefined;
}

export const countDownReminingReset = () => {
  localStorage.removeItem('hasRemaining');
}