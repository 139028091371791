export const AmericaAvailableCountries = [
  "MX",
  "CO",
  "CA",
  "EC",
  "GT",
  "BR",
  "HN",
  "AR",
];

export const EuropeAvailableCountries = ["GB", "PT", "ES"];

export const AfricaAvailableCountries = ["ZA"];

export const OceaniaAvailableCountries = ["AU"];

export const ComingCountries = [
  "IN",
  "VN",
  "SG",
  "CH",
  "MY",
  "UY",
  "KR",
  "PE",
  "PH",
];
