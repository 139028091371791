import { StateCreator, create } from "zustand";
import { devtools } from "zustand/middleware";
import { TwoFactorResponse, TwoFactorPayload } from "@/models/Auth";
import { AuthService } from "@/services/auth.service";

interface TwoFactorState {
  error: string | null;
  loading: boolean;
  setError: (error: string | null) => void;
  twoFactor: (data: TwoFactorPayload) => Promise<TwoFactorResponse | undefined>;
}

const storeApi: StateCreator<TwoFactorState> = (set) => ({
  error: null,
  loading: false,
  setError: (error: string | null) => {
    set({ error });
  },
  twoFactor: async ({ email, password, twoFactorCode }) => {
    set({
      error: null,
      loading: true,
    });

    try {
      const response = await AuthService.twoFactor({
        email,
        password,
        twoFactorCode,
      });

      return response;
    } catch (error) {
      set({
        error: String(error)?.replace("Error:", ""),
      });
    } finally {
      set({
        loading: false,
      });
    }
  },
});

export const useTwoFactorStore = create<TwoFactorState>()(
  devtools(storeApi, { name: "two-factor-storage" })
);
