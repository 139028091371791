import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSignUpStore } from "@/stores/auth/signup.store";
import { useSearchParams } from "react-router-dom";
import { PasswordsForm } from "@/components/PasswordsForm";
import { Passwords } from "@/models/Auth";

interface SignupFormProps {
  onLoginClick: () => void;
  onSuccess: () => void;
}

const SignupForm = ({ onLoginClick, onSuccess }: SignupFormProps) => {
  const [t] = useTranslation("global");
  const { error, signUp } = useSignUpStore();

  const [searchParams] = useSearchParams();

  const activationToken = useMemo(() => {
    return searchParams.get("token");
  }, [searchParams]);

  const onSubmit = async (data: Passwords) => {
    if (!activationToken) {
      close();
      return;
    }

    const succeeded = await signUp({
      password: data?.password,
      confirmPassword: data?.confirmPassword,
      activationToken,
    });

    if (succeeded) {
      onSuccess();
    }
  };

  return (
    <div className="">
      <h3
        className={`
        text-2xl 
        font-semibold 
        p-4 
        text-center 
        bg-gradient-to-r 
        from-sendolab-gold
        via-sendolab-gold3
        to-sendolab-gold4 
        text-transparent 
        bg-clip-text`}
      >
        {t("Auth.AlreadyHere")}
      </h3>
      <p className="text-[#E2E1D9] text-lg font-semibold mb-4">
        {t("Auth.CreatePassword")}
      </p>

      <PasswordsForm error={error} onSubmit={onSubmit} />

      <div className="text-center text-sm font-normal mt-2 mb-4">
        <span className="text-white mr-2">
          {t("Auth.AlreadyHaveAnAccount")}
        </span>
        <span
          onClick={onLoginClick}
          className="text-sendolab-light-turquoise font-semibold cursor-pointer underline"
        >
          {t("Auth.LogIn")}
        </span>
      </div>
    </div>
  );
};

export default SignupForm;
