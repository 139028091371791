import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  OneAllowedSymbolRegex,
  OneLowercaseLetterRegex,
  OneNumberRegex,
  OneUppercaseLetterRegex,
  PasswordRegex,
} from "@/constants/Regex";
import { Passwords } from "@/models/Auth";
import { Input } from "@/components/Input";
import { ArrowLongRightIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { Button } from "@/components/Button";
import CheckCircle from "@/assets/Icons/circle-check.svg?react";

const PASSWORD_MIN_CHARACTERS = 10;

interface PasswordsFormProps {
  error?: string | null;
  onSubmit: (data: Passwords) => Promise<void>;
}

export const PasswordsForm = ({ error, onSubmit }: PasswordsFormProps) => {
  const [t] = useTranslation("global");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const {
    formState,
    handleSubmit: submit,
    register,
    setError,
  } = useForm<Passwords>({
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  const errors = useMemo(() => formState.errors, [formState]);

  const passwordsMatch = useMemo(() => {
    return (
      password !== "" && confirmPassword !== "" && password === confirmPassword
    );
  }, [password, confirmPassword]);
  const hasEigthOrMoreCharacters = useMemo(() => {
    return password?.length >= PASSWORD_MIN_CHARACTERS;
  }, [password]);
  const hasOneUppercaseLetter = useMemo(() => {
    return password?.match(OneUppercaseLetterRegex);
  }, [password]);
  const hasOneLowercaseLetter = useMemo(() => {
    return password?.match(OneLowercaseLetterRegex);
  }, [password]);
  const hasOneNumber = useMemo(() => {
    return password?.match(OneNumberRegex);
  }, [password]);
  const hasOneAllowedSymbol = useMemo(() => {
    return password?.match(OneAllowedSymbolRegex);
  }, [password]);

  const handleSubmit = async (data: Passwords) => {
    if (!passwordsMatch) {
      setError("confirmPassword", {
        message: "Passwords should match",
      });
      return;
    }

    if (onSubmit) {
      setLoading(true);
      await onSubmit(data);
      setLoading(false);
    }
  };

  return (
    <form onSubmit={submit(handleSubmit)}>
      <div className="relative mb-3">
        <Input
          type="password"
          placeholder={t("Auth.PasswordPlaceholder")}
          error={Boolean(errors?.password)}
          errorMessage={errors?.password?.message}
          maxLength={100}
          {...register("password", {
            onChange: (event) => setPassword(event?.target?.value),
            pattern: PasswordRegex,
            required: true,
          })}
        />
      </div>
      <div className="relative">
        <Input
          type="password"
          placeholder={t("Auth.ConfirmPasswordPlaceholder")}
          error={Boolean(errors?.confirmPassword)}
          errorMessage={errors?.confirmPassword?.message}
          maxLength={100}
          {...register("confirmPassword", {
            onChange: (event) => setConfirmPassword(event?.target?.value),
            pattern: PasswordRegex,
            required: true,
          })}
        />
      </div>
      {error && (
        <p className="w-full text-red-500 font-semibold text-sm mt-4">
          {error}
        </p>
      )}

      <div className="flex flex-col items-center justify-center mt-4 sm:mt-10">
        <p className="text-[#E2E1D9] font-semibold mb-4">
          {t("Auth.PasswordMustHave")}
        </p>
        <div className="w-3/4">
          <div className="flex justify-start gap-2 mb-3">
            <CheckCircle
              className={classNames("transition-all", {
                "text-sendolab-light-turquoise": hasEigthOrMoreCharacters,
                "text-gray-400": !hasEigthOrMoreCharacters,
              })}
            />
            <span className="text-white text-xs sm:text-sm font-medium">
              {t("Auth.8CharactersRequirement")}
            </span>
          </div>
          <div className="flex justify-start gap-2 mb-3">
            <CheckCircle
              className={classNames("transition-all", {
                "text-sendolab-light-turquoise":
                  hasOneUppercaseLetter && hasOneLowercaseLetter,
                "text-gray-400":
                  !hasOneUppercaseLetter || !hasOneLowercaseLetter,
              })}
            />
            <span className="text-white text-xs sm:text-sm font-medium">
              {t("Auth.LowerAndCapitalLetters")}
            </span>
          </div>
          <div className="flex justify-start gap-2 mb-3">
            <CheckCircle
              className={classNames("transition-all", {
                "text-sendolab-light-turquoise": hasOneNumber,
                "text-gray-400": !hasOneNumber,
              })}
            />
            <span className="text-white text-xs sm:text-sm font-medium">
              {t("Auth.ANumber")}
            </span>
          </div>
          <div className="flex justify-start gap-2 mb-2">
            <CheckCircle
              className={classNames("transition-all", {
                "text-sendolab-light-turquoise": hasOneAllowedSymbol,
                "text-gray-400": !hasOneAllowedSymbol,
              })}
            />
            <span className="text-white text-xs sm:text-sm font-medium">
              {t("Auth.ASymbol")}
            </span>
          </div>
        </div>
        <div className="mt-4 sm:mt-10 w-full">
          <Button
            type="submit"
            className={`
              !bg-gradient-to-r 
              !from-[#908257]
              !to-[#c7b37f]
              text-sm
              sm:text-base 
              font-semibold 
              !border-0 
              hover:opacity-90 w-full`}
            icon={<ArrowLongRightIcon className="w-6 sm:w-10 h-6 sm:h-10" />}
            loading={loading}
            size="lg"
            text={t("Auth.CreatePasswordButton")}
          />
        </div>
      </div>
    </form>
  );
};
