import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Bars3Icon } from "@heroicons/react/24/outline";
import { useOutsideClick } from "@/hooks/useOutsideClick";
import { Button } from "@/components/Button";
import sendolaBlackLogo from "@/assets/Img/sendola-black-white-logo.png";
import HubspotContactForm from "@/components/HubspotContactForm";
import SendolaBlackLogo from "@/assets/Img/sendola-black-logo.png";
import SendolaIcon from "@/assets/Img/sendola-icon.png";
import { Modal } from "@/components/Modal";
import classNames from "classnames";
import { AuthCard } from "@/components/AuthCard";
import { LoginForm } from "@/components/LoginForm";
import SignupForm from "../SignUp";
import { useSearchParams } from "react-router-dom";
import Cookies from "universal-cookie";
import { ResetPasswordForm } from "@/components/ResetPasswordForm";
import ChangePassword from "@/components/ChangePasswordForm";

const menuItem =
  "flex w-full gap-2 items-center py-3 text-white hover:text-purple-300 cursor-pointer border-b-[1px] border-b-gray-600 last:border-b-0";
const navItem = "text-sm hover:text-gray-300 flex-shrink-0";

const MAIN_WEBSITE_URL = import.meta.env.VITE_MAIN_WEBSITE_DOMAIN;
const SENDOLA_BLACK_APP_URL = import.meta.env.VITE_SENDOLA_BLACK_APP_URL;

const cookies = new Cookies();

export const Header = () => {
  const [t] = useTranslation("global");
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [menuTouched, setMenuTouched] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState<boolean>(false);
  const [isResetPassModalOpen, setIsResetPassModalOpen] =
    useState<boolean>(false);
  const [isChangePassModalOpen, setIsChangePassModalOpen] =
    useState<boolean>(false);
  const [showNavItems, setShowNavItems] = useState<boolean>(true);
  const [isRegisterModalOpen, setIsRegisterModalOpen] =
    useState<boolean>(false);
  const [loadingForm, setLoadingForm] = useState<boolean>(false);
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [successSignup, setSuccessSignup] = useState<boolean>(false);
  const [scrollDirection, setScrollDirection] = useState<"down" | "up">("up");
  const [visible, setVisible] = useState(true);

  const [searchParams] = useSearchParams();

  const activationToken = useMemo(() => {
    return searchParams.get("token");
  }, [searchParams]);
  const resetPasswordToken = useMemo(() => {
    return searchParams.get("reset_token");
  }, [searchParams]);

  const openModal = () => {
    setLoadingForm(true);
    setFormSubmitted(false);
    setIsModalOpen(true);
  };

  const ref = useOutsideClick(() => {
    if (menuOpen) setMenuOpen(false);
  });

  const handleRequestTransfer = () => {
    const token = cookies.get("jwt_auth");

    if (token) {
      window.location.href = SENDOLA_BLACK_APP_URL;
    } else {
      setIsLoginModalOpen(true);
    }
  };

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const updateScrollDirection = () => {
      const scrollY = window.scrollY;
      const direction = scrollY > lastScrollY ? "down" : "up";

      if (
        direction !== scrollDirection &&
        (scrollY - lastScrollY > 5 || scrollY - lastScrollY < -5)
      ) {
        setScrollDirection(direction);
        setVisible(direction === "up");
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };

    window.addEventListener("scroll", updateScrollDirection);

    return () => {
      window.removeEventListener("scroll", updateScrollDirection);
    };
  }, [scrollDirection]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;

      if (scrollY > 600) setShowNavItems(false);
      else setShowNavItems(true);
    };

    document.addEventListener("scroll", handleScroll);

    return () => document.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (successSignup) {
      setTimeout(() => {
        setSuccessSignup(false);
      }, 8000);
    }
  }, [successSignup]);

  useEffect(() => {
    if (!visible) {
      setMenuOpen(false);
    }
  }, [visible]);

  useEffect(() => {
    if (activationToken) {
      setIsRegisterModalOpen(true);
    } else if (resetPasswordToken) {
      setIsChangePassModalOpen(true);
    }
  }, []);

  return (
    <>
      <nav
        className={classNames(
          "z-50 sticky top-0 transition-all duration-200 ease-in",
          {
            "-translate-y-20 lg:translate-y-0": !visible,
            "translate-y-0": visible,
          }
        )}
      >
        <div
          className={classNames(
            "w-full flex justify-between items-center transition-all bg-black",
            {
              "h-16 px-4 py-2": true, // Mobile styles
              "lg:px-10 lg:h-20": true, // Desktop styles
            }
          )}
        >
          <img
            className="h-10 lg:h-14 w-auto object-contain mr-4"
            src={sendolaBlackLogo}
            alt="Sendola's Logo"
          />
          <div
            ref={ref}
            className="w-24 flex items-center gap-2 justify-end lg:hidden"
          >
            <a href={MAIN_WEBSITE_URL}>
              <img
                className="w-6 h-6 object-contain rounded-lg transition-all hover:scale-110 flex-shrink-0"
                src={SendolaIcon}
              />
            </a>
            <Bars3Icon
              className={classNames(
                "w-10 h-10 rounded text-white cursor-pointer lg:hover:bg-purple-950 p-2 flex-shrink-0",
                {
                  "bg-purple-950": menuTouched,
                  hidden: !showNavItems,
                }
              )}
              onClick={() => setMenuOpen(!menuOpen)}
              onTouchStart={() => setMenuTouched(true)}
              onTouchEnd={() => setMenuTouched(false)}
            />
          </div>

          <div
            className={classNames(
              "hidden h-full lg:flex items-center text-white",
              {
                "gap-8": showNavItems,
                "gap-0": !showNavItems,
              }
            )}
          >
            <a
              href={`${window.location.origin + window.location.pathname}#why-choose-us`}
              className={classNames("transition-all duration-200", {
                "opacity-0 w-0 overflow-hidden": !showNavItems,
                "opacity-100 w-auto": showNavItems,
              })}
            >
              <h1 className={navItem}>{t("SendolaBlack.Header.Solutions")}</h1>
            </a>
            <a
              href={`${window.location.origin + window.location.pathname}#boost-savings`}
              className={classNames("transition-all duration-200", {
                "opacity-0 w-0 overflow-hidden": !showNavItems,
                "opacity-100 w-auto": showNavItems,
              })}
            >
              <h1 className={navItem}>{t("SendolaBlack.Header.Savings")}</h1>
            </a>
            <a
              href={`${window.location.origin + window.location.pathname}#faqs`}
              className={classNames("transition-all duration-200", {
                "opacity-0 w-0 overflow-hidden": !showNavItems,
                "opacity-100 w-auto": showNavItems,
              })}
            >
              <h1 className={navItem}>{t("SendolaBlack.Header.FAQs")}</h1>
            </a>

            <a href={MAIN_WEBSITE_URL}>
              <img
                className="w-8 h-8 object-contain rounded-lg transition-all hover:scale-110"
                src={SendolaIcon}
              />
            </a>

            <div
              className={classNames("flex gap-4 transition-all duration-200", {
                "opacity-0 w-0 overflow-hidden": !showNavItems,
                "opacity-100 w-auto": showNavItems,
              })}
            >
              <Button
                className="!bg-gradient-to-r !from-[#908257] !to-[#c7b37f] !font-normal !border-0 hover:opacity-90 !px-6"
                size="sm"
                text={t("SendolaBlack.Header.ApplyButton")}
                onClick={openModal}
              />
              <Button
                variant="secondary"
                className="bg-transparent border-white text-white hover:bg-white/10 !px-6"
                size="sm"
                text={t("SendolaBlack.Header.RequestTransfer")}
                onClick={handleRequestTransfer}
              />
            </div>
          </div>
        </div>

        <div
          className={classNames(
            "absolute overflow-hidden transition-all w-max min-w-60 rounded-b-lg flex-col bg-black/85 shadow-lg px-4 top-16 right-0 z-20 box-border",
            {
              "max-h-80": menuOpen,
              "max-h-0": !menuOpen,
            }
          )}
        >
          <a
            href={`${window.location.origin + window.location.pathname}#why-choose-us`}
            className={menuItem}
          >
            <p className="text-sm lg:text-base">
              {t("SendolaBlack.Header.Solutions")}
            </p>
          </a>
          <a
            href={`${window.location.origin + window.location.pathname}#boost-savings`}
            className={menuItem}
          >
            <p className="text-sm lg:text-base">
              {t("SendolaBlack.Header.Savings")}
            </p>
          </a>
          <a
            href={`${window.location.origin + window.location.pathname}#faqs`}
            className={menuItem}
          >
            <p className="text-sm lg:text-base">
              {t("SendolaBlack.Header.FAQs")}
            </p>
          </a>
        </div>
      </nav>

      <AuthCard
        show={isRegisterModalOpen}
        close={() => setIsRegisterModalOpen(false)}
        allowClickOutside={false}
        className={`
          !h-auto 
          !w-[406px] 
          !bg-[radial-gradient(circle_at_400px_200px,_var(--tw-gradient-stops))]
          from-[#695729]
          via-[#090909]
          to-[#090909]
        `}
      >
        <SignupForm
          onLoginClick={() => {
            setIsRegisterModalOpen(false);
            setIsLoginModalOpen(true);
          }}
          onSuccess={() => {
            setIsRegisterModalOpen(false);
            setSuccessSignup(true);
            setIsLoginModalOpen(true);
          }}
        />
      </AuthCard>
      <AuthCard
        show={isChangePassModalOpen}
        close={() => setIsChangePassModalOpen(false)}
        allowClickOutside={false}
        className={`
          !h-auto 
          !w-[406px] 
          !bg-[radial-gradient(circle_at_400px_200px,_var(--tw-gradient-stops))]
          from-[#695729]
          via-[#090909]
          to-[#090909]
        `}
      >
        <ChangePassword
          onLoginClick={() => {
            setIsChangePassModalOpen(false);
            setIsLoginModalOpen(true);
          }}
          onSuccess={() => {
            setIsChangePassModalOpen(false);
            setSuccessSignup(true);
            setIsLoginModalOpen(true);
          }}
        />
      </AuthCard>
      <AuthCard
        show={isLoginModalOpen}
        close={() => setIsLoginModalOpen(false)}
      >
        <LoginForm
          apply={() => {
            setIsLoginModalOpen(false);
            setIsModalOpen(true);
          }}
          forgotPassword={() => {
            setIsLoginModalOpen(false);
            setIsModalOpen(false);
            setIsRegisterModalOpen(false);
            setIsResetPassModalOpen(true);
          }}
          showSuccessSignup={successSignup}
        />
      </AuthCard>

      <AuthCard
        show={isResetPassModalOpen}
        close={() => setIsResetPassModalOpen(false)}
      >
        <ResetPasswordForm
          onLoginClick={() => {
            setIsResetPassModalOpen(false);
            setIsLoginModalOpen(true);
          }}
        />
      </AuthCard>

      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className="w-full flex flex-col items-center">
          {!formSubmitted && (
            <img
              className="w-48 md:w-80 mb-10"
              src={SendolaBlackLogo}
              alt="Sendola black's logo"
            />
          )}
          {loadingForm && (
            <p className="w-full text-center">{t("Loading")}...</p>
          )}
          <HubspotContactForm
            region="na1"
            portalId="44207752"
            formId="8338283d-2d9c-43c1-ad9d-dedd8617ea5c"
            onReady={() => {
              setLoadingForm(false);
            }}
            onSubmit={() => {
              setFormSubmitted(true);
            }}
          />
        </div>
      </Modal>
    </>
  );
};
