import { useState, useEffect } from "react";

interface CountdownTimerProps {
  text: string;
  initialTime: number; // Time expressed in seconds
  onComplete?: () => void;
}

const CountdownTimer = ({
  text,
  initialTime,
  onComplete,
}: CountdownTimerProps) => {
  const [seconds, setSeconds] = useState(initialTime);
  const [minutes, setMinutes] = useState(initialTime);
  const [hours, setHours] = useState(initialTime);
  const [startTime] = useState<Date>(new Date());

  useEffect(() => {
    const timerInterval = setInterval(() => {
      const now = new Date();
      const elapsedSeconds = Math.floor(
        (now?.getTime() - startTime?.getTime()) / 1000
      );
      const newTimeLeft = Math.max(initialTime - elapsedSeconds, 0);
      setSeconds(newTimeLeft % 60);
      setMinutes(Math.floor((newTimeLeft % 3600) / 60));
      setHours(Math.floor(newTimeLeft / 3600));

      if (onComplete && newTimeLeft === 0) {
        clearInterval(timerInterval);
        onComplete();
      }
    }, 100);

    return () => clearInterval(timerInterval);
  }, [initialTime, startTime]);

  return (
    <div className="w-full text-white text-sm text-center">
      <span>{text}</span>
      <span>{`${hours ? `${hours} Hours ` : ""}${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`}</span>
    </div>
  );
};

export default CountdownTimer;
