import { ReactNode, useState } from "react";
import TriangleIcon from "@/assets/Icons/triangle.svg?react";
import classNames from "classnames";

interface QuestionItemProps {
  color: "black" | "white";
  question: string;
  answer?: string;
  line?: boolean;
  children?: ReactNode;
}

export const QuestionItem = ({
  color,
  question,
  answer,
  line = true,
  children,
}: QuestionItemProps) => {
  const [active, setActive] = useState<boolean>(true);

  return (
    <div
      className={`w-full flex flex-col justify-center text-${color} overflow-hidden`}
    >
      <div
        className="flex justify-between cursor-pointer"
        onClick={() => setActive(!active)}
      >
        <p
          className="text-lg md:text-2xl lg:text-3xl font-light my-2 pr-6 select-none"
          dangerouslySetInnerHTML={{ __html: question }}
        />
        <TriangleIcon
          className={`text-sendolab-turquoise w-10 h-10 ${active ? "-rotate-180" : ""} transition-all`}
        />
      </div>
      <div
        className={classNames("transition-all", {
          "max-h-0": !active,
          "max-h-[1200px] my-4": active,
        })}
      >
        <p
          className="text-sm md:text-base"
          dangerouslySetInnerHTML={{ __html: answer || "" }}
        />
        {children}
      </div>

      {line && <div className="w-full h-[2px] bg-sendolab-turquoise"></div>}
    </div>
  );
};
