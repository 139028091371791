import { StateCreator, create } from "zustand";
import { devtools } from "zustand/middleware";
import { ChangePasswordPayload, ResetPasswordPayload } from "@/models/Auth";
import { AuthService } from "@/services/auth.service";

interface ResetPasswordState {
  error: string | null;
  loading: boolean;
  sendResetPasswordToken: (data: ResetPasswordPayload) => Promise<boolean>;
  changePassword: (data: ChangePasswordPayload) => Promise<boolean>;
}

const storeApi: StateCreator<ResetPasswordState> = (set) => ({
  error: null,
  loading: false,
  sendResetPasswordToken: async ({ email }) => {
    set({
      error: null,
      loading: true,
    });

    try {
      const response = await AuthService.sendResetPasswordToken({
        email,
      });

      return response;
    } catch (error) {
      set({
        error: String(error)?.replace("Error:", ""),
      });
    } finally {
      set({
        loading: false,
      });
    }

    return false;
  },
  changePassword: async ({ password, confirmPassword, resetPasswordToken }) => {
    set({
      error: null,
      loading: true,
    });

    try {
      const response = await AuthService.resetPassword({
        password,
        confirmPassword,
        resetPasswordToken,
      });

      return response;
    } catch (error) {
      set({
        error: String(error)?.replace("Error:", ""),
      });
    } finally {
      set({
        loading: false,
      });
    }
    return false;
  },
});

export const useResetPasswordStore = create<ResetPasswordState>()(
  devtools(storeApi, { name: "reset-password-storage" })
);
