import { Button } from "@/components/Button";
import { Input } from "@/components/Input";
import { useLoginStore } from "@/stores/auth/login.store";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { LogInPayload, TwoFactorForm } from "@/models/Auth";
import { useState } from "react";
import { useTwoFactorStore } from "@/stores/auth/twofactor.store";
import { jwtDecode, JwtPayload } from "jwt-decode";
import Cookies from "universal-cookie";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import CountdownTimer from "@/components/CountdownTimer";

interface LoginFormProps {
  apply?: () => void;
  forgotPassword?: () => void;
  showSuccessSignup?: boolean;
}

const cookies = new Cookies();
const SendolaBlackAppUrl = import.meta.env.VITE_SENDOLA_BLACK_APP_URL;
const DOMAIN = import.meta.env.VITE_COOKIE_DOMAIN;

export const LoginForm = ({
  apply,
  forgotPassword,
  showSuccessSignup = false,
}: LoginFormProps) => {
  const [t] = useTranslation("global");
  const [otpSent, setOtpSent] = useState<boolean>(false);
  const [resendEnabled, setResendEnabled] = useState<boolean>(false);
  const [validatedUser, setValidatedUser] = useState<LogInPayload>();
  const { loading: loginLoading, error: loginError, logIn } = useLoginStore();
  const {
    loading: twoFactorLoading,
    error: twoFactorError,
    twoFactor,
    setError,
  } = useTwoFactorStore();

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<LogInPayload>({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const {
    formState: { errors: twoFactorErrors },
    handleSubmit: twoFactorSubmit,
    reset: resetTwofactor,
    register: twoFactorRegister,
  } = useForm<TwoFactorForm>({
    defaultValues: {
      twoFactorCode: "",
    },
  });

  const onSubmit = async (data: LogInPayload) => {
    const response = await logIn({
      password: data?.password,
      email: data?.email,
    });

    if (response?.succeeded) {
      setValidatedUser(data);
      setOtpSent(true);
      setResendEnabled(false);
    }
  };

  const onOtpSubmit = async (data: TwoFactorForm) => {
    setError(null);
    if (!validatedUser) {
      setOtpSent(false);
      return;
    }

    const response = await twoFactor({
      email: validatedUser.email,
      password: validatedUser.password,
      twoFactorCode: data.twoFactorCode,
    });

    if (response?.data.token) {
      const token = response?.data.token;
      let decoded: JwtPayload | null = null;

      try {
        decoded = jwtDecode(token);
      } catch (error) {
        console.log(error);
      }

      let exp: number;
      if (decoded) exp = decoded?.exp || 60;
      else exp = 60;

      cookies.set("jwt_auth", token, {
        expires: new Date(exp * 1000),
        domain: DOMAIN,
      });

      setValidatedUser(undefined);
      window.location.href = SendolaBlackAppUrl;
    } else {
      resetTwofactor();
    }
  };

  const handleResend = async () => {
    if (!validatedUser) return;

    const response = await logIn({
      email: validatedUser.email,
      password: validatedUser.password,
    });
    setResendEnabled(false);

    if (response?.succeeded) {
      setResendEnabled(false);
    }
  };

  const backToLogin = () => {
    resetTwofactor();
    setOtpSent(false);
  };

  if (otpSent)
    return (
      <div className="flex flex-col h-full">
        <div className="w-full mt-4">
          <ArrowLeftIcon
            className="text-sendolab-light-turquoise w-6 h-6 cursor-pointer"
            onClick={backToLogin}
          />
        </div>
        <form
          onSubmit={twoFactorSubmit(onOtpSubmit)}
          className="flex flex-col w-full h-full gap-3 p-3"
        >
          <p className="text-sendolab-gold font-semibold text-xl text-center select-none">
            {t("Auth.TwoFactor")}
          </p>
          <p className="text-white text-center select-none text-sm mb-4">
            {t("Auth.ProtectInformation")}
          </p>
          <Input
            type="text"
            placeholder={t("Auth.AccessCodePlaceholder")}
            error={Boolean(twoFactorErrors?.twoFactorCode)}
            errorMessage={twoFactorErrors?.twoFactorCode?.message}
            {...twoFactorRegister("twoFactorCode", {
              required: true,
            })}
          />
          {twoFactorError && (
            <p className="w-full text-red-500 font-semibold text-sm">
              {t("Auth.TwoFactorError")}
            </p>
          )}

          <div className="w-full flex flex-col gap-2 mb-6 items-center">
            {!resendEnabled ? (
              <CountdownTimer
                text="Code expires in: "
                initialTime={180}
                onComplete={() => setResendEnabled(true)}
              />
            ) : (
              <p className="text-sm text-white text-center">
                Code expires in: 00:00
              </p>
            )}

            {resendEnabled && (
              <p
                className="rounded-full py-2 px-4 hover:bg-white/10 w-fit underline text-sm text-center font-semibold text-sendolab-light-turquoise underline-offset-2 cursor-pointer"
                onClick={handleResend}
              >
                {t("Auth.ResendCode")}
              </p>
            )}
          </div>

          <Button
            block
            type="submit"
            size="lg"
            text={t("Auth.TwoFactorButton")}
            loading={twoFactorLoading}
            className="bg-sendolab-gold2 !border-0 !font-normal text-base py-3"
          />
        </form>
      </div>
    );

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col w-full h-full gap-3 p-3"
    >
      <p className="text-sendolab-gold font-semibold text-2xl text-center select-none">
        {t("Auth.PremiumPayments")}
      </p>
      <p className="text-white mt-4 select-none">{t("Auth.LogIn")}</p>
      <Input
        type="text"
        placeholder={t("Auth.EmailPlaceholder")}
        error={Boolean(errors?.email)}
        errorMessage={errors?.email?.message}
        {...register("email", {
          required: true,
        })}
      />
      <Input
        type="password"
        placeholder={t("Auth.PasswordPlaceholder")}
        error={Boolean(errors?.password)}
        errorMessage={errors?.password?.message}
        {...register("password", {
          required: true,
        })}
      />
      {loginError && (
        <p className="w-full text-red-500 font-semibold text-sm">
          {t("Auth.LogInError")}
        </p>
      )}
      <p
        className="w-full text-xs text-center font-semibold underline underline-offset-2 text-sendolab-light-turquoise hover:opacity-80 cursor-pointer select-none"
        onClick={forgotPassword}
      >
        {t("Auth.ForgotPassword")}
      </p>
      {showSuccessSignup && (
        <p className="w-full text-sm text-center text-green-300 h-20">
          {t("Auth.PasswordSet")}
        </p>
      )}

      <div className="flex flex-1" />

      <Button
        block
        type="submit"
        size="lg"
        text={t("Auth.LogIn")}
        loading={loginLoading}
        className="bg-sendolab-gold2 !border-0 !font-normal text-base py-3"
      />
      <div className="w-full flex justify-center gap-1">
        <span className="text-xs text-white">
          {t("Auth.DontHaveAnAccount")}
        </span>
        <span
          className="text-xs underline underline-offset-2 text-sendolab-light-turquoise font-semibold cursor-pointer"
          onClick={apply}
        >
          {t("Auth.ApplyToday")}
        </span>
      </div>
    </form>
  );
};
