import {
  ChangeEvent,
  FocusEvent,
  forwardRef,
  KeyboardEvent,
  useState,
} from "react";
import classNames from "classnames";

import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";

interface InputProps {
  id?: string;
  name?: string;
  type?: string;
  value?: string;
  defaultValue?: string | number;
  max?: number | string;
  min?: number | string;
  size?: "sm" | "md" | "lg";
  inputMode?: "text" | "numeric" | "decimal" | "tel" | "email";
  placeholder?: string;
  maxLength?: number;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: string;
  block?: boolean;
  className?: string;
  enterKeyHint?:
    | "done"
    | "enter"
    | "go"
    | "next"
    | "previous"
    | "search"
    | "send";
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      id,
      name,
      type = "text",
      value,
      defaultValue,
      max,
      min,
      size = "md",
      inputMode,
      placeholder,
      maxLength,
      disabled = false,
      error,
      errorMessage,
      block = true,
      className,
      enterKeyHint,
      onChange,
      onBlur,
      onFocus,
      onKeyDown,
    }: InputProps,
    ref
  ) => {
    const [isPasswordShown, setIsPasswordShown] = useState<boolean>(false);

    const showPassword = () => {
      setIsPasswordShown(!isPasswordShown);
    };

    return (
      <div
        className={classNames("relative flex flex-col gap-1", {
          "w-full": block,
          "w-72": !block,
        })}
      >
        <input
          className={classNames(
            "px-3 py-2 rounded-lg border-2 w-full",
            {
              "border-red-500": error,
              "border-gray-300": !error,
              "text-sm": size === "sm",
              "text-base": size === "md",
              "text-lg": size === "lg",
            },
            className
          )}
          id={id}
          name={name}
          inputMode={inputMode}
          maxLength={maxLength}
          disabled={disabled}
          placeholder={placeholder}
          value={value}
          defaultValue={defaultValue}
          max={max}
          min={min}
          type={type === "password" && isPasswordShown ? "text" : type}
          enterKeyHint={enterKeyHint}
          ref={ref}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
        />
        {error && errorMessage && (
          <p className="text-red-500 text-sm">{errorMessage}</p>
        )}
        {type === "password" &&
          (isPasswordShown ? (
            <EyeSlashIcon
              className={classNames(
                "absolute right-0 top-0 w-9 h-9 z-10 p-2 m-1 bg-white cursor-pointer select-none hover:bg-gray-200 transition-all rounded-lg text-sendolab-light-turquoise"
              )}
              onClick={showPassword}
            />
          ) : (
            <EyeIcon
              className={classNames(
                "absolute right-0 top-0 w-9 h-9 z-10 p-2 m-1 bg-white cursor-pointer select-none hover:bg-gray-200 transition-all rounded-lg text-gray-500"
              )}
              onClick={showPassword}
            />
          ))}
      </div>
    );
  }
);
