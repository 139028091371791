import { AxiosError } from "axios";
import { baseApi } from "@/services";
import {
  ChangePasswordPayload,
  LogInPayload,
  LogInResponse,
  ResendTwoFactorPayload,
  ResendTwoFactorResponse,
  ResetPasswordPayload,
  SignUpPayload,
  TwoFactorPayload,
  TwoFactorResponse,
} from "@/models/Auth";

export class AuthService {
  static logIn = async ({
    email,
    password,
  }: LogInPayload): Promise<LogInResponse> => {
    try {
      const response = await baseApi.post<LogInResponse>("login", {
        GrantType: "password",
        UserName: email,
        Password: password,
        Channel: "email",
      });

      return response?.data;
    } catch (error) {
      if (error instanceof AxiosError && error.status !== 404) {
        throw new Error(error.response?.data?.errors?.join(", "));
      }

      throw new Error("Invalid email or password");
    }
  };

  static resendTwoFactor = async ({
    email,
    channel,
  }: ResendTwoFactorPayload): Promise<ResendTwoFactorResponse> => {
    try {
      const url = `/resend-twofactor`;
      const response = await baseApi.post<ResendTwoFactorResponse>(url, {
        UserName: email,
        Channel: channel || null,
      });

      return response?.data;
    } catch (error) {
      if (error instanceof AxiosError && error.status !== 404) {
        throw new Error(error.response?.data?.errors?.join(", "));
      }

      throw new Error("Unable to resend the code. Please try again");
    }
  };

  static twoFactor = async ({
    email,
    password,
    twoFactorCode,
  }: TwoFactorPayload): Promise<TwoFactorResponse> => {
    try {
      const url = `/login-twofactor`;
      const response = await baseApi.post<TwoFactorResponse>(url, {
        UserName: email,
        Password: password,
        TwoFactorCode: twoFactorCode,
      });

      return response?.data;
    } catch (error) {
      if (error instanceof AxiosError && error.status !== 404) {
        throw new Error(error.response?.data?.errors?.join(", "));
      }

      throw new Error(
        "There was an issue. Please, wait a moment and try again."
      );
    }
  };

  static signUp = async ({
    password,
    confirmPassword,
    activationToken,
  }: SignUpPayload): Promise<boolean> => {
    try {
      const url = `/users/sign-up`;
      const response = await baseApi.put(
        url,
        {
          Password: password,
          ConfirmPassword: confirmPassword,
        },
        {
          headers: {
            activationToken,
          },
        }
      );

      if (response.status === 204) {
        return true;
      }

      return false;
    } catch (error) {
      console.log(error);
      throw new Error(
        "This link has expired or was already used. Please contact your concierge for assistance."
      );
    }
  };

  static sendResetPasswordToken = async ({
    email,
  }: ResetPasswordPayload): Promise<boolean> => {
    try {
      const url = `/password/reset/generatetoken`;
      const response = await baseApi.post<boolean>(url, {
        UserName: email,
      });

      if (response.status === 200) {
        return true;
      }

      return false;
    } catch (error) {
      console.log(error);
      throw new Error(
        "Failed to send reset link. Please check your email address and try again."
      );
    }
  };

  static resetPassword = async ({
    password,
    confirmPassword,
    resetPasswordToken,
  }: ChangePasswordPayload): Promise<boolean> => {
    try {
      const url = `/password/reset`;
      const response = await baseApi.put(
        url,
        {
          Password: password,
          ConfirmPassword: confirmPassword,
        },
        {
          headers: {
            resetPasswordToken,
          },
        }
      );

      if (response.status === 204) {
        return true;
      }

      return false;
    } catch (error) {
      console.log(error);
      throw new Error(
        "Failed to update password. Please ensure the link is correct and try again."
      );
    }
  };
}
