import classNames from "classnames";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

export interface CountriesCardProps {
  title: string;
  image?: ReactNode;
  orientation: "vertical" | "horizontal";
  countryList: string[];
  addMoreToComeText?: boolean;
  className?: string;
  listWrapperClassName?: string;
}

export const CountriesCard = ({
  title,
  image,
  orientation = "vertical",
  countryList,
  className,
  listWrapperClassName,
}: CountriesCardProps) => {
  const [t] = useTranslation("global");

  return (
    <div className={classNames("flex flex-col gap-4 box-border", className)}>
      <p className="text-center text-sendolab-turquoise-foreground font-semibold">
        {title}
      </p>

      <div
        className={classNames(
          "w-full h-full flex justify-center items-center gap-x-4 xs:gap-x-10 gap-y-4 rounded-2xl bg-sendolab-turquoise3 p-4",
          {
            "flex-col": orientation === "vertical",
            "flex-col md:flex-row": orientation === "horizontal",
          }
        )}
      >
        {image && (
          <div
            className={classNames({
              "w-full sm:w-2/5": orientation === "horizontal",
            })}
          >
            {image}
          </div>
        )}
        <div
          className={classNames(
            "flex flex-wrap max-w-[260px] items-center gap-y-4 gap-x-8 my-4",
            listWrapperClassName
          )}
        >
          {countryList.map((country, index) => (
            <div
              key={`country-card-${index}`}
              className="flex gap-2 items-center w-[110px]"
            >
              <img
                className="w-8 h-8 rounded-full object-cover flex-shrink-0"
                src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${country}.svg`}
                alt={`${t(`Countries.${country}`)}'s Flag`}
              />
              <span className="text-xs text-white text-nowrap">
                {t(`Countries.${country}`)}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
