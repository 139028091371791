import { StateCreator, create } from "zustand";
import { devtools } from "zustand/middleware";
import { SignUpPayload } from "@/models/Auth";
import { AuthService } from "@/services/auth.service";

interface SignUpState {
  error: string | null;
  loading: boolean;
  signUp: (data: SignUpPayload) => Promise<boolean | undefined>;
}

const storeApi: StateCreator<SignUpState> = (set) => ({
  error: null,
  loading: false,
  signUp: async ({ password, confirmPassword, activationToken }) => {
    set({
      error: null,
      loading: true,
    });

    try {
      const response = await AuthService.signUp({
        password,
        confirmPassword,
        activationToken,
      });

      return response;
    } catch (error) {
      set({
        error: String(error)?.replace("Error:", ""),
      });
    } finally {
      set({
        loading: false,
      });
    }

    return false;
  },
});

export const useSignUpStore = create<SignUpState>()(
  devtools(storeApi, { name: "sign-up-storage" })
);
