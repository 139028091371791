import { StateCreator, create } from "zustand";
import { devtools } from "zustand/middleware";
import { LogInPayload, LogInResponse } from "@/models/Auth";
import { AuthService } from "@/services/auth.service";

interface LoginState {
  error: string | null;
  loading: boolean;
  logIn: (data: LogInPayload) => Promise<LogInResponse | undefined>;
}

const storeApi: StateCreator<LoginState> = (set) => ({
  error: null,
  loading: false,
  logIn: async ({ email, password }) => {
    set({
      error: null,
      loading: true,
    });

    try {
      const response = await AuthService.logIn({
        email,
        password,
      });

      return response;
    } catch (error) {
      set({
        error: String(error)?.replace("Error:", ""),
        // This part should handle the format/translation of errors, so the could be user visible,
        // to handle the error logging for developers we should use the handle on the corresponding .service file
      });
    } finally {
      set({
        loading: false,
      });
    }
  },
});

export const useLoginStore = create<LoginState>()(
  devtools(storeApi, { name: "login-storage" })
);
